let DomainListMixin = {
  data() {
    return {
      selectedDomains: [],
      actionRunning: false
    }
  },
  methods: {
    itemClass(item) {
      if (item.is_deleted === 1) {
        return 'inactive-domain'
      }
    },
    getDomainURL(domain) {
      // Strip out domain prefix, like in web-repo
      return `http://${domain.replace(/^(%\d+%)/g, '')}`
    },
    getFolderName(folderId) {
      return this.$store.getters['folder/getNameById'](folderId)
    },
    getGrid() {
      return this.$refs.grid
    },
    getGridDataTotal() {
      return this.$refs.grid.data.total
    },
    getIsBulkEnabled() {
      return this.$refs.grid && this.$refs.grid.isBulkEnabled
    },
    getGridApiArguments() {
      return this.$refs.grid && this.$refs.grid.getApiArguments()
    },
    getDialogTitle(actionText) {
      if (this.getIsBulkEnabled()) {
        return actionText + ' ' + this.getGridDataTotal() + ' ' + this.$t('misc.domains')
      } else if (this.selectedDomains.length > 1) {
        return actionText + ' ' + this.selectedDomains.length + ' ' + this.$t('misc.domains')
      } else if (this.selectedDomains.length === 1) {
        return actionText + ' "' + this.selectedDomains[0].domainidn + '"'
      }
    },
    getApiArgsForBulkUpdate() {
      let apiArguments = {}

      if (this.getGrid()) {
        apiArguments = this.getGridApiArguments()
      }

      delete apiArguments.page
      delete apiArguments.per_page

      return apiArguments
    },
    openEditPage(domains) {
      this.$store.dispatch('domain/setFullPath', this.$route.fullPath)
      if (this.getIsBulkEnabled()) {
        this.$store.dispatch('domain/setSelectedDomainsCount', this.getGridDataTotal())
        this.$store.dispatch('domain/setBatchUpdateDomains', [])
        this.$router.push({ name: 'domains_edit_multiple', query: this.getApiArgsForBulkUpdate() })
      } else if (domains.length > 1) {
        this.$store.dispatch('domain/setSelectedDomainsCount', domains.length)
        this.$store.dispatch('domain/setBatchUpdateDomains', domains)
        this.$router.push({ name: 'domains_edit_multiple' })
      } else {
        this.$store.dispatch('domain/setDomain', domains[0])
        this.$router.push({ name: 'domains_edit', params: { domain: domains[0].domain } })
      }
    },
    openActionDialog(domains, component) {
      this.setAndOpenActionComponent(domains, component)
    },
    closeActionDialog(component) {
      this[component] = false
    },
    moveToFolder() {
      if (this.moveToFolderId === null) {
        this.$store.dispatch('snackbar/showErrorMessage', { message: this.$t('domain_index.folder_not_set') })
      } else {
        this.getIsBulkEnabled()
          ? this.bulkRequest('edit', { folder: this.moveToFolderId }, 'showMoveToFolderDialog')
          : this.batchRequest('PUT', { folder: this.moveToFolderId }, 'moved', 'showMoveToFolderDialog')
        this.moveToFolderId = null
      }
    },
    deleteDomain() {
      this.getIsBulkEnabled()
        ? this.bulkRequest('delete', {}, 'showDeleteDomainDialog')
        : this.batchRequest('DELETE', {}, 'deleted', 'showDeleteDomainDialog')
    },
    bulkRequest(action, updateValues, component) {
      const apiArgsForBulkUpdate = this.getApiArgsForBulkUpdate()
      const bulkArguments = {
        sort: apiArgsForBulkUpdate.sort
      }

      let bulkFilters = apiArgsForBulkUpdate
      delete bulkFilters.sort

      const data = {
        action: action,
        filters: bulkFilters,
        arguments: bulkArguments,
        update_values: updateValues
      }

      this.actionRunning = true
      this.$store
        .dispatch('bulk_request/store', data)
        .then(async () => {
          await this.$store.dispatch('snackbar/showInfoMessage', {
            message: this.$t('bulk.bulk_queued'),
            subMessage: this.$t('bulk.bulk_domains_updated')
          })

          this.resetAndCloseActionComponent(component)

          this.$refs.grid.selected = []
          this.$refs.grid.isBulkEnabled = false
          this.actionRunning = false
        })
        .catch(() => {
          this.$parent.$emit('api_error')
          this.actionRunning = false
        })
    },
    batchRequest(method, parameters, mode, component) {
      const batches = this.selectedDomains.map(selectedDomain => {
        return {
          route: '/domains/' + selectedDomain.domain,
          method: method,
          id: selectedDomain.domain,
          parameters: parameters
        }
      })
      this.actionRunning = true
      this.$store
        .dispatch('batch/store', { requests: batches })
        .then(async response => {
          if (response.errorIds.length === 0) {
            await this.$store.dispatch('snackbar/showSuccessMessage', {
              message: this.$tc('domain_index.domain_' + mode + '_success', batches.length)
            })
          } else if (response.errorIds.length === batches.length) {
            await this.$store.dispatch('snackbar/showErrorMessage', {
              message: this.$tc('domain_index.domain_' + mode + '_error', batches.length)
            })
          } else {
            let message =
              this.$tc('domain_index.domain_' + mode + '_warning_count_success', batches.length - response.errorIds.length) +
              ' ' +
              this.$tc('domain_index.domain_' + mode + '_warning_count_failed', response.errorIds.length)
            await this.$store.dispatch('snackbar/showWarningMessage', { message: message, details: response.errorIds })
          }
          this.resetAndCloseActionComponent(component)
          this.$refs.grid.selected = []
          this.actionRunning = false
          this.$refs.grid.getDataFromApi() // Calling Method from inside Grid component
        })
        .catch(() => {
          this.$parent.$emit('api_error')
          this.actionRunning = false
        })
    },
    setAndOpenActionComponent(domains, component) {
      this.selectedDomains = domains
      this[component] = true
    },
    resetAndCloseActionComponent(component) {
      this.selectedDomains = []
      this[component] = false
    },
    loadSaleData() {
      this.$store.dispatch('sale_banner/fetchData', { per_page: 1000 })
      this.$store.dispatch('sale_url/fetchData', { per_page: 1000 })
      this.$store.dispatch('sale_text/fetchData', { per_page: 1000 })
      this.$store.dispatch('sale_template/fetchData', { per_page: 1000 })
    }
  },
  created() {
    if (this?.apiArgs?.restarting?.search?.value === undefined) {
      return
    }
    this.apiArgs.restarting.search.value = this.$route.query.search || ''
  },
  watch: {
    '$route.query.search': function() {
      this.apiArgs.restarting.search.value = this.$route.query.search || ''
    }
  }
}

export default DomainListMixin
