<template>
  <Grid
    ref="grid"
    :additional-api-arguments="apiArgs"
    :enable-pagination="true"
    :default-sort-by="defaultSortBy"
    :default-sort-desc="defaultSortDesc"
    :pagination-show-first-last-page="paginationShowFirstLastPage"
    :pagination-show-totals="paginationShowTotals"
    :headers="headers"
    data-store-action="deleted_domain/fetchData"
    form-name="domain-deleted-form"
    item-key="domainidn"
  >
    <!-- filter slot with additional filter -->
    <template v-slot:filters>
      <v-col class="col-12 col-sm-6 col-md-2 filter-search">
        <v-text-field
          :label="$t('domain_deleted.search')"
          :value="apiArgs.restarting.search.value"
          dense
          filled
          hide-details
          placeholder=""
          @change="apiArgs.restarting.search.value = $event"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </template>
    <template v-slot:[`item.domainidn`]="{ item }">
      <router-link :to="'/stats/domain/date/' + item.domain" class="domain-stats-link">
        <span class="domain">{{ item.domainidn }}</span>
      </router-link>
    </template>
    <template v-slot:[`item.datedel`]="{ item }">
      <span class="datedel">{{ $f.shortDate(item.datedel) }}</span>
    </template>
  </Grid>
</template>

<script>
import Grid from '@/components/grid/Grid'
import GridBaseMixin from '@/components/grid/GridBaseMixin'
import DomainListMixin from '@/components/domain/DomainListMixin'

export default {
  mixins: [GridBaseMixin, DomainListMixin],
  components: {
    Grid
  },
  data() {
    return {
      paginationShowFirstLastPage: true,
      paginationShowTotals: true,
      data: {
        total: 0
      },
      defaultSortBy: ['datedel'],
      defaultSortDesc: [true],
      headers: [
        { text: this.$t('domain_deleted.domain'), value: 'domainidn', sortable: false },
        { text: this.$t('domain_deleted.deleted_date'), value: 'datedel', sortable: true }
      ],
      apiArgs: {
        restarting: {
          search: {
            value: this.queryParamOrDefaultValue('search', null),
            check: search => search !== '',
            argumentProvider: (apiArguments, search) => {
              if (search !== '') {
                apiArguments['domain'] = search
              }
            }
          }
        },
        persistent: {}
      }
    }
  },
  mounted() {
    this.$on('api_data_retrieved', data => {
      let hasTotal = data != null && data.total != null
      this.paginationShowFirstLastPage = hasTotal
      this.paginationShowTotals = hasTotal
    })
  }
}
</script>
