<template>
  <div id="domain-deleted">
    <Headline />
    <DomainDeleted />
  </div>
</template>

<script>
import Headline from '@/components/app/Headline'
import DomainDeleted from '@/components/domain/DomainDeleted.vue'

export default {
  components: {
    Headline,
    DomainDeleted
  }
}
</script>
